/**
 * ===================================================================
 *
 *  Kards v1.0 Vendor/Third Party CSS
 *  url: styleshout.com
 *  03-01-2016
 *  ------------------------------------------------------------------
 *  TOC:
 *  01. Core Owl Carousel CSS File
 *  02. Magnific Popup CSS
 *
 * ===================================================================
 */

/*
 *  01. Core Owl Carousel CSS File
 *	 v1.3.3
 */

/* clearfix */
.owl-carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

/* display none until init */
.owl-carousel {
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper {
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer {
	overflow: hidden;
	position: relative;
	width: 100%;
}
.owl-carousel .owl-wrapper-outer.autoHeight {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}
.owl-carousel .owl-item {
	float: left;
}
.owl-controls .owl-page, .owl-controls .owl-buttons div {
	cursor: pointer;
}
.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
}

/* mouse grab icon */
/*.grabbing {
	cursor: url(grabbing.png) 8 8, move;
}*/

/* fix */
.owl-carousel .owl-wrapper, .owl-carousel .owl-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
}

/*
 *  Owl Carousel CSS3 Transitions
 *  v1.3.2
 */
.owl-origin {
	-webkit-perspective: 1200px;
	-webkit-perspective-origin-x: 50%;
	-webkit-perspective-origin-y: 50%;
	-moz-perspective: 1200px;
	-moz-perspective-origin-x: 50%;
	-moz-perspective-origin-y: 50%;
	perspective: 1200px;
}

/* fade */
.owl-fade-out {
	z-index: 10;
	-webkit-animation: fadeOut .7s both ease;
	-moz-animation: fadeOut .7s both ease;
	animation: fadeOut .7s both ease;
}
.owl-fade-in {
	-webkit-animation: fadeIn .7s both ease;
	-moz-animation: fadeIn .7s both ease;
	animation: fadeIn .7s both ease;
}

/* backSlide */
.owl-backSlide-out {
	-webkit-animation: backSlideOut 1s both ease;
	-moz-animation: backSlideOut 1s both ease;
	animation: backSlideOut 1s both ease;
}
.owl-backSlide-in {
	-webkit-animation: backSlideIn 1s both ease;
	-moz-animation: backSlideIn 1s both ease;
	animation: backSlideIn 1s both ease;
}

/* goDown */
.owl-goDown-out {
	-webkit-animation: scaleToFade .7s ease both;
	-moz-animation: scaleToFade .7s ease both;
	animation: scaleToFade .7s ease both;
}
.owl-goDown-in {
	-webkit-animation: goDown .6s ease both;
	-moz-animation: goDown .6s ease both;
	animation: goDown .6s ease both;
}

/* scaleUp */
.owl-fadeUp-in {
	-webkit-animation: scaleUpFrom .5s ease both;
	-moz-animation: scaleUpFrom .5s ease both;
	animation: scaleUpFrom .5s ease both;
}
.owl-fadeUp-out {
	-webkit-animation: scaleUpTo .5s ease both;
	-moz-animation: scaleUpTo .5s ease both;
	animation: scaleUpTo .5s ease both;
}

/* Keyframes */

/*empty*/
@-webkit-keyframes empty {
	0% {
		opacity: 1;
	}
}
@-moz-keyframes empty {
	0% {
		opacity: 1;
	}
}
@keyframes empty {
	0% {
		opacity: 1;
	}
}
@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-moz-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes backSlideOut {
	25% {
		opacity: .5;
		-webkit-transform: translateZ(-500px);
	}
	75% {
		opacity: .5;
		-webkit-transform: translateZ(-500px) translateX(-200%);
	}
	100% {
		opacity: .5;
		-webkit-transform: translateZ(-500px) translateX(-200%);
	}
}
@-moz-keyframes backSlideOut {
	25% {
		opacity: .5;
		-moz-transform: translateZ(-500px);
	}
	75% {
		opacity: .5;
		-moz-transform: translateZ(-500px) translateX(-200%);
	}
	100% {
		opacity: .5;
		-moz-transform: translateZ(-500px) translateX(-200%);
	}
}
@keyframes backSlideOut {
	25% {
		opacity: .5;
		transform: translateZ(-500px);
	}
	75% {
		opacity: .5;
		transform: translateZ(-500px) translateX(-200%);
	}
	100% {
		opacity: .5;
		transform: translateZ(-500px) translateX(-200%);
	}
}
@-webkit-keyframes backSlideIn {
	0%, 25% {
		opacity: .5;
		-webkit-transform: translateZ(-500px) translateX(200%);
	}
	75% {
		opacity: .5;
		-webkit-transform: translateZ(-500px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateZ(0) translateX(0);
	}
}
@-moz-keyframes backSlideIn {
	0%, 25% {
		opacity: .5;
		-moz-transform: translateZ(-500px) translateX(200%);
	}
	75% {
		opacity: .5;
		-moz-transform: translateZ(-500px);
	}
	100% {
		opacity: 1;
		-moz-transform: translateZ(0) translateX(0);
	}
}
@keyframes backSlideIn {
	0%, 25% {
		opacity: .5;
		transform: translateZ(-500px) translateX(200%);
	}
	75% {
		opacity: .5;
		transform: translateZ(-500px);
	}
	100% {
		opacity: 1;
		transform: translateZ(0) translateX(0);
	}
}
@-webkit-keyframes scaleToFade {
	to {
		opacity: 0;
		-webkit-transform: scale(0.8);
	}
}
@-moz-keyframes scaleToFade {
	to {
		opacity: 0;
		-moz-transform: scale(0.8);
	}
}
@keyframes scaleToFade {
	to {
		opacity: 0;
		transform: scale(0.8);
	}
}
@-webkit-keyframes goDown {
	from {
		-webkit-transform: translateY(-100%);
	}
}
@-moz-keyframes goDown {
	from {
		-moz-transform: translateY(-100%);
	}
}
@keyframes goDown {
	from {
		transform: translateY(-100%);
	}
}
@-webkit-keyframes scaleUpFrom {
	from {
		opacity: 0;
		-webkit-transform: scale(1.5);
	}
}
@-moz-keyframes scaleUpFrom {
	from {
		opacity: 0;
		-moz-transform: scale(1.5);
	}
}
@keyframes scaleUpFrom {
	from {
		opacity: 0;
		transform: scale(1.5);
	}
}
@-webkit-keyframes scaleUpTo {
	to {
		opacity: 0;
		-webkit-transform: scale(1.5);
	}
}
@-moz-keyframes scaleUpTo {
	to {
		opacity: 0;
		-moz-transform: scale(1.5);
	}
}
@keyframes scaleUpTo {
	to {
		opacity: 0;
		transform: scale(1.5);
	}
}


/*
 *  02. Magnific Popup CSS
 */
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #0b0b0b;
	opacity: 0.8;
	filter: alpha(opacity=80);
}
.mfp-wrap {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1043;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden;
}
.mfp-container {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 8px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.mfp-container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
	display: none;
}
.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 1045;
}
.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto;
}
.mfp-ajax-cur {
	cursor: progress;
}
.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}
.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
	cursor: auto;
}
.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.mfp-loading.mfp-figure {
	display: none;
}
.mfp-hide {
	display: none !important;
}
.mfp-preloader {
	color: #CCC;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: 1044;
}
.mfp-preloader a {
	color: #CCC;
}
.mfp-preloader a:hover {
	color: #FFF;
}
.mfp-s-ready .mfp-preloader {
	display: none;
}
.mfp-s-error .mfp-content {
	display: none;
}
button.mfp-close, button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
	display: block;
	outline: none;
	padding: 0;
	z-index: 1046;
	-webkit-box-shadow: none;
	box-shadow: none;
}
button::-moz-focus-inner {
	padding: 0;
	border: 0;
}
.mfp-close {
	width: 44px;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover, .mfp-close:focus {
	opacity: 1;
	filter: alpha(opacity=100);
}
.mfp-close:active {
	top: 1px;
}
.mfp-close-btn-in .mfp-close {
	color: #333;
}
.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
	color: #FFF;
	right: -6px;
	text-align: right;
	padding-right: 6px;
	width: 100%;
}
.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #CCC;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
}
.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 90px;
	height: 110px;
	-webkit-tap-highlight-color: transparent;
}
.mfp-arrow:active {
	margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
	opacity: 1;
	filter: alpha(opacity=100);
}
.mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
	content: '';
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
	margin-top: 35px;
	margin-left: 35px;
	border: medium inset transparent;
}
.mfp-arrow:after, .mfp-arrow .mfp-a {
	border-top-width: 13px;
	border-bottom-width: 13px;
	top: 8px;
}
.mfp-arrow:before, .mfp-arrow .mfp-b {
	border-top-width: 21px;
	border-bottom-width: 21px;
	opacity: 0.7;
}
.mfp-arrow-left {
	left: 0;
}
.mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
	border-right: 17px solid #FFF;
	margin-left: 31px;
}
.mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
	margin-left: 25px;
	border-right: 27px solid #3F3F3F;
}
.mfp-arrow-right {
	right: 0;
}
.mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
	border-left: 17px solid #FFF;
	margin-left: 39px;
}
.mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
	border-left: 27px solid #3F3F3F;
}
.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
	top: -40px;
}
.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #000;
}

/* Main image in popup */
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 40px 0 40px;
	margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
	line-height: 0;
}
.mfp-figure:after {
	content: '';
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #444;
}
.mfp-figure small {
	color: #BDBDBD;
	display: block;
	font-size: 12px;
	line-height: 14px;
}
.mfp-figure figure {
	margin: 0;
}
.mfp-bottom-bar {
	margin-top: -36px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}
.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #F3F3F3;
	word-wrap: break-word;
	padding-right: 36px;
}
.mfp-image-holder .mfp-content {
	max-width: 100%;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}
@media screen and (max-width:800px) and (orientation:landscape), screen and (max-height:300px) {

	/**
	     * Remove all paddings around the image on small screen
	     */
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}
	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}
	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px;
	}
	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0;
	}
}
@media all and (max-width:900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}
	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		transform-origin: 0;
	}
	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		transform-origin: 100%;
	}
	.mfp-container {
		padding-left: 6px;
		padding-right: 6px;
	}
}
.mfp-ie7 .mfp-img {
	padding: 0;
}
.mfp-ie7 .mfp-bottom-bar {
	width: 600px;
	left: 50%;
	margin-left: -300px;
	margin-top: 5px;
	padding-bottom: 5px;
}
.mfp-ie7 .mfp-container {
	padding: 0;
}
.mfp-ie7 .mfp-content {
	padding-top: 44px;
}
.mfp-ie7 .mfp-close {
	top: 0;
	right: 0;
	padding-top: 0;
}

/*# sourceMappingURL=vendor.css.map */